import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-unsubscribe-template-container',
  templateUrl: './unsubscribe-template-container.component.html',
  styleUrls: ['./unsubscribe-template-container.component.scss']
})
export class UnsubscribeTemplateContainerComponent {
  @Input() headline = '';
  @Input() subHeadline = '';
  @Input() description = '';
  @Input() mailAddressRemoval1 = '';
  @Input() mailAddressRemoval2 = '';
  @Input() buttonTxt = '';
  @Input() isUnsubscribedPage = false;
  @Output() onBtnClick = new EventEmitter();
  private appService = inject(AppService);
  public email$ = this.appService.emailAddress$;

  onClick() {
    this.isUnsubscribedPage
      ? (window.location.href = 'https://www.porsche.com')
      : this.onBtnClick.emit();
  }
}
