<header class="header__title">
  <a no-referrer href="https://www.porsche.com" rel="noopener">
    <img no-referrer src="../assets/images/porsche_logo.svg" alt="Porsche" />
  </a>
</header>
<ng-container *ngIf="!(isUnsubscribed$ | async)">
  <app-unsubscribe-newsletter> </app-unsubscribe-newsletter>
</ng-container>
<ng-container *ngIf="isUnsubscribed$ | async">
  <app-unsubscribed-newsletter> </app-unsubscribed-newsletter>
</ng-container>
<footer>
  <pnav-footer [env]="env.env" [locale]="env.locale" />
</footer>
