import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment.production';

import {
  applyPolyfills,
  defineCustomElements as definePorscheFooter
} from '@porsche-nav/footer/loader';
import { enableProdMode } from '@angular/core';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

applyPolyfills().then(() => {
  definePorscheFooter(window);
});

if (environment.env === 'production') {
  enableProdMode();
}
