<header class="header__title">
  <a no-referrer href="https://www.porsche.com" rel="noopener">
    <img no-referrer src="../assets/images/porsche_logo.svg" alt="Porsche" />
  </a>
</header>
<ng-container
  *ngIf="
    (listUnsubscribed$ | async) || (isUnsubscribed$ | async);
    else landingPageTemplate
  "
>
  <app-unsubscribed-newsletter> </app-unsubscribed-newsletter>
</ng-container>
<ng-template #landingPageTemplate>
  <app-unsubscribe-newsletter> </app-unsubscribe-newsletter>
</ng-template>
<footer>
  <pnav-footer [env]="env.env" [locale]="env.locale" />
</footer>
