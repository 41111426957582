import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  EMPTY,
  catchError,
  map,
  mergeMap,
  of,
  tap
} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastManager } from '@porsche-design-system/components-angular';
import { environment } from '../environments/environment';

interface IUnsubscribeParameters {
  id?: string;
  'newsletter-type'?: string;
  importer: string;
}
@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private toast: ToastManager
  ) {}
  public isUnsubscribed$ = new BehaviorSubject(false);
  public emailAddress$ = this.activatedRoute.queryParams.pipe(
    map(queryParams => queryParams['Email'])
  );
  public newsletterType$ = this.activatedRoute.queryParams.pipe(
    map(queryParams => queryParams['Newsletter'])
  );
  // when user directly unsubscribes from email service provider
  public listUnsubscribed$ = this.activatedRoute.queryParams.pipe(
    map(queryParams => queryParams['ListUnsubscribe'])
  );

  unsubscribeNewsletter() {
    return this.activatedRoute.queryParams
      .pipe(
        mergeMap(queryParams => {
          const unsubscribeParams = {
            id: queryParams['ID'] ? queryParams['ID'] : undefined,
            'newsletter-type': queryParams['Newsletter']
              ? queryParams['Newsletter']
              : undefined,
            importer: queryParams['Importer']
              ? queryParams['Importer']
              : undefined
          };
          return this.areParamsDefined(unsubscribeParams)
            ? this.http.post(environment.lambdaEndpoint, unsubscribeParams)
            : of(undefined);
        })
      )
      .pipe(
        tap(val => {
          if (val) {
            this.isUnsubscribed$.next(true);
          } else {
            this.isUnsubscribed$.next(false);
            // TODO replace with transloco service after language requirement is addressed
            this.toast.addMessage({
              text: "Couldn't be unsubscribed, Please try again",
              state: 'neutral'
            });
          }
        }),
        catchError(error => {
          console.log('err', error);
          this.toast.addMessage({
            text: "Couldn't be unsubscribed, Please try again",
            state: 'neutral'
          });
          return EMPTY;
        })
      );
  }

  areParamsDefined(params: IUnsubscribeParameters): boolean {
    return Object.values(params).every(val => val !== undefined);
  }
}
