import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { TranslocoRootModule } from './transloco-root.module';

// components
import { UnsubscribeTemplateContainerComponent } from './components/unsubscribe-template-container/unsubscribe-template-container.component';
import { UnsubscribeNewsletterComponent } from './components/unsubscribe-newsletter/unsubscribe-newsletter.component';
import { UnsubscribedNewsletterComponent } from './components/unsubscribed-newsletter/unsubscribed-newsletter.component';
@NgModule({
  declarations: [
    AppComponent,
    UnsubscribeTemplateContainerComponent,
    UnsubscribeNewsletterComponent,
    UnsubscribedNewsletterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PorscheDesignSystemModule,
    TranslocoRootModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
